<template>
  <div class="wrap">
    <div class="logins">
      <div class="logins_top">
        <setLanguage />
      </div>
      <div class="logins_page_logo"></div>
      <div class="logins_content">
        <div class="title">{{ $t('login.welcome') }}</div>
      </div>
      <div class="logins_form">
        <Form class="form_list" validate-trigger="onSubmit">
          <Field v-model="formValue[item.key]" :placeholder="$t(item.label)" v-for="(item, index) in formList"
            :key="`form_item_${index}`" :rules="item.rules || []" :maxlength="item.length || 99"
            :type="item.type || 'text'" :border="false">
            <template #left-icon>
              <van-icon :name="item.icon" class="icon" />
            </template>
          </Field>
          <div class="btns">
            <div @click="onSubmit" color="#16a1ba" class="confirm">
              {{ $t('newAdd.Login') }}
            </div>
          </div>
          <div class="btn_group">
            <div class="service" @click="service">
              {{ $t('login.service') }}
              <img src="../../assets/new/login/service.png" alt="">
            </div>
            <div class="line">|</div>
            <div class="register" @click="toRegiste">{{ $t('login.register') }}</div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import setLanguage from "@/components/setLanguage.vue";
import { LOGIN } from "@/api";
import { $post } from "../../utils/request";
import { Icon, Field, Form, Button as Btn, Checkbox, Toast, } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
    Field,
    Form,
    Btn,
    Checkbox,
    setLanguage
  },
  data() {
    return {
      formList: [{
        key: "userphone",
        flag: true,
        icon: "contact",
        label: "Login.nameInput",
        rules: [{ required: false, message: this.$t("hint.usernameEnter") }],
      }, {
        label: "Login.pwdInput",
        type: "password",
        key: "userpass",
        icon: "lock",
        flag: true,
        rules: [{ required: false, message: this.$t("hint.passwordEnter") }],
        isPassword: true,
      }],
      formValue: {
        userphone: '',
        password: ''
      },
      read: false,
    };
  },
  methods: {
    service() {
      this.$store.dispatch("toService");
    },
    onSubmit() {
      if (!this.formValue.userphone) return Toast(this.$t("hint.usernameEnter"));
      if (!this.formValue.userpass) return Toast(this.$t("hint.passwordEnter"));
      Toast.loading({ duration: 0 });
      LOGIN(this.formValue).then((r) => {
        if (r.data.ret === 1) {
          this.$cookie.set("userInfo", JSON.stringify(r.data.data));
          this.$cookie.set("token", r.data.data.token);
          this.$cookie.set("the-pwd-state", this.checked);
          if (this.read) {
            this.$cookie.set(
              "the-pwd-obj",
              JSON.stringify({
                userphone: this.formValue.userphone,
                userpass: this.formValue.userpass,
              })
            );
          } else {
            this.$cookie.remove("the-pwd-obj");
          }
        } else {
          Toast.clear();
          Toast(r.data.msg);
        }
        return r.data.ret;
      }).then(async (ret) => {
        if (ret == 1) {
          this.$store.dispatch("getSystemConfig");
          try {
            const res = await $post("/users/info");
            const { ret, msg, data } = res.data;
            if (ret == 1) {
              Toast.clear();
              this.$router.replace({ name: "goods" });
              this.$cookie.set("userInfo", JSON.stringify(data));
            }
          } catch (error) {
            Toast.clear();
          }
        }
      }).catch((e) => {
        Toast.clear();
      });
    },
    toRegiste() {
      this.$router.push("/registrasi");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: var(--bg);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0 calc(20rem / 16);
  background-image: url('../../assets/new/login/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .logins {
    flex: 1;
    padding-top: calc(40rem / 16);

    &_top {
      display: flex;
      justify-content: flex-end;
    }

    &_page_logo {
      margin: auto;
      width: calc(195rem / 16);
      height: calc(120rem / 16);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: url('../../assets/new/login/logo.png');
      position: relative;
      z-index: 1;
      margin-top: calc(20rem / 16);
    }

    .common {
      position: relative;
      z-index: 1;
    }

    &_content {
      .common();
      padding: calc(20rem / 16);
      margin-top: calc(10rem / 16);

      .title {
        font-size: calc(24rem / 16);
        font-family: YouSheBiaoTiHei;
        font-weight: normal;
        line-height: calc(30rem / 16);
        font-variation-settings: "opsz" auto;
        color: var(--light);
        text-shadow: 0px 3px 3px #3E8597;
        text-align: center;
      }

      .btn {
        background-image: linear-gradient(179deg, #13a2ba, #087c95);
        color: var(--light);
        width: max-content;
        padding: 0 calc(10rem / 16);
        height: calc(36rem / 16);
        font-size: calc(14rem / 16);
        font-weight: 400;
        border-radius: calc(7rem / 16);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: calc(10rem / 16);
      }
    }

    &_form {
      .common();
      padding: calc(7rem / 16);

      .form_list {
        /deep/ .van-cell {
          background-color: var(--light);
          padding: calc(6rem / 16) calc(9rem / 16);
          margin-bottom: calc(15rem / 16);
          border-radius: calc(10rem / 16);
          .van-field__left-icon{
            display: flex;
            align-items: center;
          }
          input::placeholder {
            line-height: calc(32rem / 16);
            color: var(--colors);
            font-size: calc(15rem / 16);
          }

          input {
            line-height: calc(32rem / 16);
            color: var(--textColor);
            font-size: calc(15rem / 16);
          }
        }

        .icon {
          font-size: calc(20rem / 16);
          margin-right: calc(5rem / 16);
          color: #3E8597;
        }

        .btns {
          margin-top: calc(30rem / 16);

          .confirm {
            height: calc(54rem / 16);
            color: var(--light);
            border-radius: calc(10rem / 16);
            background-image: linear-gradient(179deg, #13a2ba, #087c95);
            font-size: calc(18rem / 16);
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }
        }

        .btn_group {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--light);
          font-size: calc(14rem / 16);
          padding: calc(20rem / 16) 0 calc(10rem / 16);

          .service {
            flex: 0 0 calc(50% - 10rem / 16);
            display: flex;
            align-items: center;
            text-align: right;
            img{
              width: calc(24rem / 16);
              height: calc(24rem / 16);
              display: block;
              margin-left: calc(5rem / 16);
            }
          }
          .line{
            margin: 0 calc(10rem / 16);
          }
          .register {
            flex: 0 0 calc(50% - 10rem / 16);
          }
        }
      }
    }
  }
}
</style>
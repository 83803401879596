<template>
  <div class="set_Lan">
    <div class="cont" v-if="isShow" @click="open">
      <div class="span">{{ language }}</div>
      <van-icon class="icon" name="arrow-down" />
    </div>
    <PopModel :list="lans" @submit="setLan" ref="popRef" />
  </div>
</template>

<script>
import { Icon, Popup } from 'vant';
import { Lans, getLanguage } from '@/i18n';
import Cookies from "js-cookie";
import PopModel from '@/components/pop.vue'
export default {
  components: {
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    PopModel
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      popShow: false,
      lans: Lans,
      language: ''
    }
  },
  created() {
    this.language = this.lans.find(item => item.value == getLanguage()).name
  },
  methods: {
    open() {
      this.$refs.popRef && this.$refs.popRef.open()
    },
    setLan({ item }) {
      this.$i18n.Locale = item.value;
      this.changeLanguage(item.value)
      Cookies.set('language', item.value)
      this.language = item.name;
      this.$refs.popRef && this.$refs.popRef.close()
      this.$emit('submit')
    }
  }
}
</script>
<style lang="less" scoped>
.set_Lan {
  position: relative;
  z-index: 10000;
  margin-top: calc(4rem / 16);
  display: flex;
  align-items: center;

  .cont {
    width: max-content;
    padding: 0 calc(14rem / 16);
    height: calc(30rem / 16);
    border-radius: calc(3rem / 16);
    background-color: #009688;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: var(--light);

    .span {
      display: block;
      font-size: calc(15rem / 16);
      padding-right: calc(16rem / 16);
    }
  }


}
</style>